var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property, html, unsafeCSS, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { triggerEvent } from './../../libs/utils';
import { customElement } from './../../libs/decorators';
import { TagName as TabContent } from './tab-content';
import styles from './style.scss';
let Tabs = class Tabs extends LitElement {
    constructor() {
        super(...arguments);
        this.labels = 'First,Second';
        this.active = 0;
        this.fitted = false;
    }
    _showTab(index) {
        const tabs = this.querySelectorAll(TabContent);
        tabs.forEach((e, i) => i === index ? e.setAttribute('visible', '') : e.removeAttribute('visible'));
    }
    _setActive(evt) {
        evt.preventDefault();
        const index = parseInt(evt.target.dataset.index);
        this.active = index;
        this._showTab(index);
        triggerEvent(this, 'change', { active: index });
    }
    render() {
        this._showTab(this.active);
        const classes = {
            'fitted': this.fitted
        };
        const labels = this.labels.split(',') || [];
        return html `      
      <section 
        class=${classMap(classes)}
      >
        <ul
          class="tabs-list"
          role="tablist">
          ${labels.map((e, i) => html `
            <li
              class=${this.active === i ? 'active' : ''}
              role="presentation"
            >
              <a 
                href="#"
                role="tab"
                data-index=${i}
                @click=${(event) => this._setActive(event)}
              >${e}</a>
            </li>
          `)}
        </ul>
        <article
          class="tabs-content"
          role="tabpanel"
        >
          <slot></slot>
        </article>
      </section>
    `;
    }
};
Tabs.styles = unsafeCSS(styles);
__decorate([
    property({ type: String })
], Tabs.prototype, "labels", void 0);
__decorate([
    property({ type: Number })
], Tabs.prototype, "active", void 0);
__decorate([
    property({ type: Boolean })
], Tabs.prototype, "fitted", void 0);
Tabs = __decorate([
    customElement('amber-tabs')
], Tabs);
export { Tabs };
