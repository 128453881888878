const fallback = (tag) => `bitrock-${tag}`;
const define = (tag, definition) => customElements.get(tag) ?
    customElements.define(fallback(tag), definition) :
    customElements.define(tag, definition);
const labels = (list, position) => list.split(',')[position];
const triggerEvent = (element, name, detail) => {
    const event = new CustomEvent(name, {
        detail,
        bubbles: true,
        composed: true
    });
    return element.dispatchEvent(event);
};
const decodeEntities = (char) => {
    const temp = document.createElement('p');
    temp.innerHTML = char;
    return temp.textContent || temp.innerText;
};
export { labels, triggerEvent, define, decodeEntities };
