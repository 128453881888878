export const Elements = [
    'amber-banner',
    'amber-button',
    'amber-date',
    'amber-card',
    'amber-code-snippet',
    'amber-modal',
    'amber-progress',
    'amber-tabs'
];
