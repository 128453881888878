var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property, html, LitElement } from 'lit-element';
import { customElement } from './../../libs/decorators';
export const TagName = 'amber-tab-content';
let TabContent = class TabContent extends LitElement {
    constructor() {
        super(...arguments);
        this.visible = false;
    }
    render() {
        return html `
      ${this.visible ? html `<slot></slot>` : ''}
    `;
    }
};
__decorate([
    property({ type: Boolean })
], TabContent.prototype, "visible", void 0);
TabContent = __decorate([
    customElement(TagName)
], TabContent);
export { TabContent };
