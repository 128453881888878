import { html } from 'lit-html';
const check = (prop) => prop && prop.length;
const taxonomy = (value) => check(value) ? html `<h6>${value}</h6>` : html ``;
const media = (value, title) => check(value) ? html `
    <figure>
      <img 
        src=${value} 
        alt=${title}
      >
    </figure>
  ` : html ``;
export { taxonomy, media };
