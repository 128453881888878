var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property, html, unsafeCSS, LitElement } from 'lit-element';
import { triggerEvent } from './../../libs/utils';
import { customElement } from './../../libs/decorators';
import styles from './style.scss';
const _navigator = navigator;
let CodeSnippet = class CodeSnippet extends LitElement {
    constructor() {
        super(...arguments);
        this.clipboard = false;
        this.label = 'Copy';
    }
    _copy(evt) {
        const code = this.innerHTML;
        const content = code.length ? code.trim() : code;
        _navigator.clipboard.writeText(content)
            .then(() => triggerEvent(this, 'copied', { content }));
    }
    render() {
        const button = () => this.clipboard ? html `

      <amber-button
        class="copy"
        priority="tertiary"
        state="neutral"
        @click=${(event) => this._copy(event)}
      >
        ${this.label}
      </amber-button>
    ` : html ``;
        return html `      
      <section>
        ${button()}
        <pre>
          <code>
            <slot></slot>
          </code>
        </pre>
      </section>
    `;
    }
};
CodeSnippet.styles = unsafeCSS(styles);
__decorate([
    property({ type: Boolean })
], CodeSnippet.prototype, "clipboard", void 0);
__decorate([
    property({ type: String })
], CodeSnippet.prototype, "label", void 0);
CodeSnippet = __decorate([
    customElement('amber-code-snippet')
], CodeSnippet);
export { CodeSnippet };
