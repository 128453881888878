var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property, html, unsafeCSS, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { labels, triggerEvent } from '../../libs/utils';
import { customElement } from './../../libs/decorators';
import styles from './style.scss';
import './../button';
const have = (key, ctx) => ctx[key] && ctx[key].length;
let Banner = class Banner extends LitElement {
    constructor() {
        super(...arguments);
        this.active = false;
        this.title = 'Title';
        this.labels = 'OK,Cancel';
        this.state = '';
    }
    show() {
        this.active = true;
    }
    hide() {
        this.active = false;
    }
    button(primary) {
        const evt = primary ? 'confirm' : 'cancel';
        this.hide();
        triggerEvent(this, evt);
    }
    render() {
        const haveTitle = have('title', this);
        const haveLabels = have('labels', this);
        const classes = {
            'info': this.state === 'info',
            'success': this.state === 'success',
            'warning': this.state === 'warning',
            'error': this.state === 'error',
        };
        const cancel = labels(this.labels, 1) ?
            html `
        <amber-button
          priority="tertiary"
          state="neutral"
          @click=${(event) => this.button(false)}
        >
          ${labels(this.labels, 1)}
        </amber-button>` : html ``;
        const confirm = labels(this.labels, 0) ?
            html `
        <amber-button
          state=${this.state}
          priority="tertiary"
          @click=${(event) => this.button(true)}
        >
          ${labels(this.labels, 0)}
        </amber-button>` : html ``;
        return html `      
      <section
        ?active=${this.active}
        class=${classMap(classes)}
      >
        <article>
          ${haveTitle ? html `
          <header class="banner-header">
            <h5>${this.title}</h5>
          </header>` : html ``}
          
          <p class="banner-content">
            <slot></slot>
          </p>
          
          ${haveLabels ? html `
          <footer class="banner-footer">
            ${cancel}
            ${confirm}
          </footer>` : html ``}
        </article>
      </section>
    `;
    }
};
Banner.styles = unsafeCSS(styles);
__decorate([
    property({ type: Boolean })
], Banner.prototype, "active", void 0);
__decorate([
    property({ type: String })
], Banner.prototype, "title", void 0);
__decorate([
    property({ type: String })
], Banner.prototype, "labels", void 0);
__decorate([
    property({ type: String })
], Banner.prototype, "state", void 0);
Banner = __decorate([
    customElement('amber-banner')
], Banner);
export { Banner };
