import { Banner } from './banner';
import { Button } from './button';
import { Date } from './date';
import { Card } from './card';
import { CodeSnippet } from './code';
import { Modal } from './modal';
import { Progress } from './progress';
import { Tabs } from './tabs';
const AmberComponents = {
    Banner,
    Button,
    Date,
    Card,
    CodeSnippet,
    Modal,
    Progress,
    Tabs
};
export { AmberComponents };
