var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property, html, unsafeCSS, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { triggerEvent } from './../../libs/utils';
import { customElement } from './../../libs/decorators';
import flatpickr from './../../libs/flatpickr/flatpickr.js';
import styles from './style.scss';
let Date = class Date extends LitElement {
    constructor() {
        super(...arguments);
        this.inline = false;
        this.disabled = false;
        this.time = false;
        this.mode = 'single';
        this.value = '';
        this.config = {};
        this.flatpickr = null;
    }
    connectedCallback() {
        super.connectedCallback();
        const defaults = {
            appendTo: this.shadowRoot,
            inline: this.inline,
            mode: this.mode,
            enableTime: this.time,
            defaultDate: this.value,
            onChange: (selectedDates, dateStr, instance) => triggerEvent(this, 'change', { selectedDates, dateStr, instance })
        };
        const config = Object.assign({}, defaults, this.config);
        setTimeout(() => {
            const elem = this.shadowRoot.querySelector('input');
            this.flatpickr = flatpickr(elem, config);
        }, 100);
    }
    open() {
        this.flatpickr.open();
    }
    close() {
        this.flatpickr.close();
    }
    render() {
        const classes = {
            'inline': this.inline,
        };
        return html `      
      <div class=${classMap(classes)}>
        <input type="text"
          value=${this.value}
          ?disabled=${this.disabled}
        />
      </div>
    `;
    }
};
Date.styles = unsafeCSS(styles);
__decorate([
    property({ type: Boolean })
], Date.prototype, "inline", void 0);
__decorate([
    property({ type: Boolean })
], Date.prototype, "disabled", void 0);
__decorate([
    property({ type: Boolean })
], Date.prototype, "time", void 0);
__decorate([
    property({ type: String })
], Date.prototype, "mode", void 0);
__decorate([
    property({ type: String })
], Date.prototype, "value", void 0);
__decorate([
    property({ type: Object })
], Date.prototype, "config", void 0);
__decorate([
    property({ type: Function })
], Date.prototype, "flatpickr", void 0);
Date = __decorate([
    customElement('amber-date')
], Date);
export { Date };
