var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property, html, unsafeCSS, svg, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { pixel, half, viewBox, radius, dashArray, dashoffset } from './maths';
import { customElement } from './../../libs/decorators';
import styles from './style.scss';
let Progress = class Progress extends LitElement {
    constructor() {
        super(...arguments);
        this.size = 48;
        this.stroke = 4;
        this.value = 25;
        this.determinate = false;
    }
    render() {
        const paths = (size, stroke, value) => svg `
      <svg
        class="progress"
        height=${pixel(size)}
        width=${pixel(size)}
        viewBox=${viewBox(size)}
      >
        <circle
          class="progress-circle"
          cx=${half(size)}
          cy=${half(size)}
          r=${radius(size, stroke)}
          stroke-width=${stroke}
        />
        <circle
          class="progress-value"
          cx=${half(size)}
          cy=${half(size)}
          r=${radius(size, stroke)}
          stroke-width=${stroke}
          stroke-dasharray=${dashArray(size, stroke)}
          stroke-dashoffset=${dashoffset(size, stroke, value)}
        />
      </svg>
    `;
        const classes = {
            'indeterminate': !this.determinate,
            'determinate': this.determinate,
        };
        return html `      
      <div
        class=${classMap(classes)}
      >
        ${paths(this.size, this.stroke, this.value)}
      </div>
    `;
    }
};
Progress.styles = unsafeCSS(styles);
__decorate([
    property({ type: Number })
], Progress.prototype, "size", void 0);
__decorate([
    property({ type: Number })
], Progress.prototype, "stroke", void 0);
__decorate([
    property({ type: Number })
], Progress.prototype, "value", void 0);
__decorate([
    property({ type: Boolean })
], Progress.prototype, "determinate", void 0);
Progress = __decorate([
    customElement('amber-progress')
], Progress);
export { Progress };
