var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property, html, unsafeCSS, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { customElement } from './../../libs/decorators';
import styles from './style.scss';
let Button = class Button extends LitElement {
    constructor() {
        super(...arguments);
        this.priority = 'secondary';
        this.state = '';
        this.disabled = false;
        this.fitted = false;
        this.nooutline = false;
        this.progress = '';
        this.type = 'button';
    }
    render() {
        const classes = {
            'fitted': this.fitted,
            'outline': this.nooutline,
            'primary': this.priority === 'primary',
            'secondary': this.priority === 'secondary',
            'tertiary': this.priority === 'tertiary',
            'success': this.state === 'success',
            'error': this.state === 'error',
            'neutral': this.state === 'neutral',
            'warning': this.state === 'warning',
            'info': this.state === 'info',
            'is-loading': this.progress === 'pending',
            'is-success': this.progress === 'success',
            'is-error': this.progress === 'error',
        };
        return html `
      <button
        type=${this.type}
        ?disabled=${this.disabled}
        class=${classMap(classes)}
      >
        <span class="content-loading"><amber-progress size="24" stroke="2" value="25"></amber-progress></span>
        <span class="content-success">Success</span>
        <span class="content-error">Error</span>
        <span class="content-default"><slot></slot></span>
      </button>
    `;
    }
};
Button.styles = unsafeCSS(styles);
__decorate([
    property({ type: String })
], Button.prototype, "priority", void 0);
__decorate([
    property({ type: String })
], Button.prototype, "state", void 0);
__decorate([
    property({ type: Boolean })
], Button.prototype, "disabled", void 0);
__decorate([
    property({ type: Boolean })
], Button.prototype, "fitted", void 0);
__decorate([
    property({ type: Boolean })
], Button.prototype, "nooutline", void 0);
__decorate([
    property({ type: String })
], Button.prototype, "progress", void 0);
__decorate([
    property({ type: String })
], Button.prototype, "type", void 0);
Button = __decorate([
    customElement('amber-button')
], Button);
export { Button };
