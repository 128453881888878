var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property, html, unsafeCSS, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { labels, triggerEvent } from '../../libs/utils';
import { customElement } from './../../libs/decorators';
import styles from './style.scss';
const have = (key, ctx) => ctx[key] && ctx[key].length;
let Modal = class Modal extends LitElement {
    constructor() {
        super(...arguments);
        this.open = false;
        this.nosubmit = false;
        this.labels = 'OK,Cancel';
        this.state = '';
        this.title = 'Title';
    }
    _dialog() {
        return this.shadowRoot.querySelector('dialog');
    }
    close(closedBy) {
        const dialog = this._dialog();
        dialog.close ?
            dialog.close(closedBy) : this.open = false;
        triggerEvent(this, 'closed', { closedBy });
    }
    showModal() {
        const dialog = this._dialog();
        dialog.showModal ?
            dialog.showModal() : this.open = true;
    }
    button(primary) {
        const evt = primary ? 'confirm' : 'cancel';
        triggerEvent(this, evt);
        !this.nosubmit || !primary ?
            this.close(`${evt} button`) : null;
    }
    render() {
        const haveTitle = have('title', this);
        const haveLabels = have('labels', this);
        const classes = {
            'info': this.state === 'info',
            'success': this.state === 'success',
            'warning': this.state === 'warning',
            'error': this.state === 'error',
        };
        const cancel = labels(this.labels, 1) ?
            html `
        <amber-button
          priority="tertiary"
          state="neutral"
          @click=${(event) => this.button(false)}
        >
          ${labels(this.labels, 1)}
        </amber-button>` : html ``;
        const confirm = labels(this.labels, 0) ?
            html `
        <amber-button
          state=${this.state}
          priority="tertiary"
          ?disabled=${this.nosubmit}
          @click=${(event) => this.button(true)}
        >
          ${labels(this.labels, 0)}
        </amber-button>` : html ``;
        return html `      
      <dialog 
        ?open=${this.open}
        class=${classMap(classes)}
      >
        <amber-button
          class="close"
          priority="tertiary"
          state="neutral"
          @click=${(event) => this.button(false)}
        >
          ✕
        </amber-button>

        <section>
          ${haveTitle ? html `
          <header class="modal-header">
            <h3>${this.title}</h3>
          </header>` : html ``}
          
          <p class="modal-content">
            <slot></slot>
          </p>

          ${haveLabels ? html `
          <footer class="modal-footer">
            ${cancel}
            ${confirm}
          </footer>` : html ``}
        <section>

      </dialog>
    `;
    }
};
Modal.styles = unsafeCSS(styles);
__decorate([
    property({ type: Boolean })
], Modal.prototype, "open", void 0);
__decorate([
    property({ type: Boolean })
], Modal.prototype, "nosubmit", void 0);
__decorate([
    property({ type: String })
], Modal.prototype, "labels", void 0);
__decorate([
    property({ type: String })
], Modal.prototype, "state", void 0);
__decorate([
    property({ type: String })
], Modal.prototype, "title", void 0);
Modal = __decorate([
    customElement('amber-modal')
], Modal);
export { Modal };
