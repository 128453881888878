var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property, html, unsafeCSS, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { taxonomy, media } from './parts';
import { customElement } from './../../libs/decorators';
import styles from './style.scss';
let Card = class Card extends LitElement {
    constructor() {
        super(...arguments);
        this.title = '';
        this.taxonomy = '';
        this.media = '';
        this.background = 'white';
    }
    render() {
        const classes = {
            'white': this.background === 'white',
            'light': this.background === 'light',
        };
        return html `      
      <article 
        class="card"
      >
        ${media(this.media, this.title)}
        <section
          class=${classMap(classes)}
        >
          ${taxonomy(this.taxonomy)}
          <h3>${this.title}</h3>
          <p><slot></slot></p>
        </section>
      </article>
    `;
    }
};
Card.styles = unsafeCSS(styles);
__decorate([
    property({ type: String })
], Card.prototype, "title", void 0);
__decorate([
    property({ type: String })
], Card.prototype, "taxonomy", void 0);
__decorate([
    property({ type: String })
], Card.prototype, "media", void 0);
__decorate([
    property({ type: String })
], Card.prototype, "background", void 0);
Card = __decorate([
    customElement('amber-card')
], Card);
export { Card };
